/** Shared stuff **/
@mixin title {
  letter-spacing: -0.02em;
  font-weight: 800;
  line-height: 135%;
}

@mixin body {
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0;
}

@mixin button {
  letter-spacing: -0.05%;
  font-weight: 500;
}

/** Font size packages **/
@mixin body-xs {
  @include body;
  font-size: 0.75rem; // 12px
}

@mixin body-s {
  @include body;
  font-size: 0.875rem; // 14px
}

@mixin body-m {
  @include body;
  font-size: 1rem; // 16px
}

@mixin body-l {
  @include body;
  font-size: 1.125rem; // 18px
}

@mixin subtitle-s {
  @include title;
  font-size: 0.875rem; // 14px
}

@mixin subtitle-m {
  @include title;
  font-size: 1rem; // 16px;
}

@mixin subtitle-l {
  @include title;
  font-size: 1.125rem; // 18px
}

@mixin title-s {
  @include title;
  font-size: 1.125rem; // 18px
}

@mixin title-m {
  @include title;
  font-size: 1.25rem; // 20px
}

@mixin title-l {
  @include title;
  font-size: 1.375rem; // 22px
}

@mixin title-xl {
  @include title;
  font-size: 1.75rem; // 28px
}

@mixin title-2xl {
  @include title;
  font-size: 2.25rem; // 36px
}

@mixin title-3xl {
  @include title;
  font-size: 2.75rem; // 44px
}

@mixin button-s {
  @include button;
  font-size: 0.875rem; // 14px
}

@mixin button-m {
  @include button;
  font-size: 1rem; // 16px
}

/** MONO **/
@mixin mono {
  font-family: 'DMMono', monospace;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 135%;
  font-weight: 400;
}

@mixin mono-xs {
  @include mono;
  font-size: 12px;
}

@mixin mono-s {
  @include mono;
  font-size: 14px;
}

@mixin mono-m {
  @include mono;
  font-size: 16px;
}

@mixin mono-l {
  @include mono;
  font-size: 18px;
}

@mixin mono-xl {
  @include mono;
  font-size: 1.5rem; // 24px
}

// markdown with encapsulated styles is not applied.
.markdownContent {
  h1 {
    @include title-xl;
  }

  p {
    @include body-m;
  }
}
