import { transition, trigger, useAnimation } from '@angular/animations'
import {
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Output,
  output,
} from '@angular/core'
import {
  _anyToErrorObject,
  _isNotEmpty,
  ErrorObject,
  HttpRequestError,
} from '@naturalcycles/js-lib'
import { AuthMode, BackendResponseFM } from '@naturalcycles/shared'
import { Decorate } from '@src/app/core/decorators/decorators'
import { AnalyticsService } from '@src/app/core/services/analytics/analytics.service'
import { MixpanelService } from '@src/app/core/services/analytics/mixpanel.service'
import { AuthService } from '@src/app/core/services/auth.service'
import { getSignalStore, SignalStore } from '@src/app/core/store/signalStore'
import { fadeAnimation, staggerAnimation } from '@src/app/core/util/animations.util'
import { isSignupMode } from '@src/app/core/util/authMode.util'
import { CommonEvent } from '@src/app/shared/typings/analytics'
import { AuthProviderType } from '@src/app/shared/typings/enum/auth'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import { AcceptConsentEvent } from '@src/app/shared/typings/interfaces/events'
import { AuthProviderResult } from '@src/app/shared/typings/interfaces/user-auth'
import { LoaderType } from '@src/app/shared/typings/ui'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    trigger('fadeAnimation', [transition('* => *', [useAnimation(fadeAnimation)])]),
    trigger('staggerAnimation', [transition('* => *', [useAnimation(staggerAnimation)])]),
  ],
  standalone: false,
})
export class AuthComponent {
  protected mode = input(AuthMode.signup)
  protected centeredHeading = input(false)
  protected showBackArrow = input(false)

  @Output()
  public showingConsent = new EventEmitter<boolean>()

  // TODO [2025-03-24] check if this issue still occurs and switch back to signal if possible https://github.com/angular/angular/issues/19826
  @Output()
  public submitResponse = new EventEmitter<BackendResponseFM>()

  public didChangeProvider = output<AuthProviderType>()

  protected authError?: ErrorObject<HttpRequestError>
  protected provider: AuthProviderType = AuthProviderType.none
  protected showConsent = false
  protected passwordType: 'password' | 'text' = 'password'
  protected AuthMode = AuthMode

  protected store = inject(SignalStore)
  private authService = inject(AuthService)
  private mixpanelService = inject(MixpanelService)
  private analyticsService = inject(AnalyticsService)

  private authResult: AuthProviderResult | undefined
  private cdRef = inject(ChangeDetectorRef)
  protected isModal = input(false)

  protected hasStartedQuiz = computed(() => _isNotEmpty(this.store.$quiz().data))

  protected isSignupMode = computed(() => isSignupMode(this.mode()))

  protected async collectConsentIfNeeded(result: AuthProviderResult): Promise<void> {
    this.authResult = result

    if (this.isSignupMode() && !result.input.accountAlreadyExists) {
      this.showConsentView()
      this.cdRef.detectChanges()
    } else {
      await this.createOrLogin({
        ...result,
        consent: {
          appConsent: true,
        },
      })
    }
  }

  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  protected async createOrLogin(result: AuthProviderResult): Promise<void> {
    let error: ErrorObject<HttpRequestError> | undefined
    try {
      const response = await this.authService.createOrLogin(result)
      this.submitResponse.emit(response)
    } catch (err) {
      error = _anyToErrorObject<HttpRequestError>(err)
      throw err
    } finally {
      this.authError = error
    }
  }

  public changeProvider(provider: AuthProviderType): void {
    this.provider = provider
    this.didChangeProvider.emit(provider)
  }

  private showConsentView(): void {
    const { supportedLanguage } = getSignalStore().$userLocale()
    void this.mixpanelService.trackView('/consent', {
      lang: supportedLanguage,
      consentCookie: undefined,
    })
    this.showConsent = true
    this.showingConsent.emit(true)
  }

  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  protected async onAcceptConsent($event: AcceptConsentEvent): Promise<void> {
    await this.createOrLogin({
      ...this.authResult!,
      consent: {
        ...$event,
        appConsent: true,
      },
    })
    this.showConsent = false
    this.showingConsent.emit(false)
  }

  protected onDeclineConsent(): void {
    this.authResult = undefined
    this.showConsent = false
    this.showingConsent.emit(false)
    // Avoid mixing state when switching between providers
    this.store.$account.update(s => ({ ...s, email: undefined as any }))

    this.analyticsService.trackEvent(CommonEvent.DeclineConsent)
    window.scrollTo(0, 0)
  }
}
