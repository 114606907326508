@import '_mixin';
// Vars

$imgPath: '/assets/img';
$containerWidth: 375px;

$active: 'body.no-touch &:not(.active):hover, &:not(.active):active';
$activeAndTouch: '&:hover, &:active';

$xxs: 'only screen and (max-width : 374px)';
$xs: 'only screen and (max-width : 575px)';
$sm: 'only screen and (min-width : 576px) and (max-width: 767px)';
$md: 'only screen and (min-width : 768px) and (max-width: 991px)';
$lg: 'only screen and (min-width : 992px) and (max-width: 1199px)';
$xl: 'only screen and (min-width : 1200px)';
$xxl: 'only screen and (min-width : 1500px)';

$smOrLess: 'only screen and (max-width: 767px)';
$mdOrLess: 'only screen and (max-width: 991px)';

$smOrMore: 'only screen and (min-width: 576px)';
$mdOrMore: 'only screen and (min-width: 768px)';
$lgOrMore: 'only screen and (min-width: 992px)';
$xlOrMore: 'only screen and (min-width: 1200px)';
$xxlOrMore: 'only screen and (min-width: 1500px)';

$smOrMd: 'only screen and (min-width : 576px) and (max-width: 991px)';
$mdOrLg: 'only screen and (min-width : 768px) and (max-width: 1199px)';

// New brand 2020
$colorWhite: #fff;
$colorBlack: #1a1a1a;
$colorPurple: #72035d;
$colorPurpleRgb: 114, 3, 93;
$colorDarkPurple: #560246;
$colorLightPink: #ffe8e8;
$colorPink: #fa837f;
$colorSoftMagenta: #efb9f8;
$colorLightBlue: #cce2ff;
$colorBlue: #488ee8;
$colorAccessibleBlue: #2674d9;
$colorVanillaIce: #e2d4d4;
$colorGreen: #a1c862;
$colorYellow: #ffbd00;
$colorRed: #d83030;
$colorLightGrey: #f5f3f3;
$colorLightGreyRgb: 245, 243, 243;
$colorMediumGrey: #f1eaea;
$colorDarkGrey: #5f5f5f;
$colorMediumDarkGrey: #a3a3a3;
$colorBorderGrey: #d6d6d6;
$colorOuraBlue: #2f4a73;
$colorUseProtectionPink: #ff736f;
$colorDigitalBlue: #2674d9;

// New brand 2023
$brandBlue: #7fb7ff;

// ab-312
$colorShimmerGradient: linear-gradient(
  259.07deg,
  #ffb9f9 4.15%,
  #ffccfa 15.43%,
  #fff5f5 47.14%,
  #cce2ff 85.08%,
  #bad9ff 96.5%
);

:root {
  /**
  * Used by NCAngular to match font sizes between WebSignup and NCApp3
  */
  --rem-base: 1.6;

  --color-white: #{$colorWhite};
  --color-black: #{$colorBlack};
  --color-purple: #{$colorPurple};
  --color-dark-purple: #{$colorDarkPurple};
  --color-light-pink: #{$colorLightPink};
  --color-pink: #{$colorPink};
  --color-light-blue: #{$colorLightBlue};
  --color-blue: #{$colorBlue};
  --color-accessible-blue: #{$colorAccessibleBlue};
  --color-vanilla-ice: #{$colorVanillaIce};
  --color-green: #{$colorGreen};
  --color-yellow: #{$colorYellow};
  --color-red: #{$colorRed};
  --color-light-grey: #{$colorLightGrey};
  --color-medium-grey: #{$colorMediumGrey};
  --color-dark-grey: #{$colorDarkGrey};
  --color-medium-dark-grey: #{$colorMediumDarkGrey};
  --color-border-grey: #{$colorBorderGrey};
  --color-oura-blue: #{$colorOuraBlue};
  --color-use-protectction-pink: #{$colorUseProtectionPink};
  --color-digital-blue: #{$colorDigitalBlue};

  //2023
  --color-brand-blue: #{$brandBlue};

  // ab-312
  --color-shimmer-gradient: #{$colorShimmerGradient};
}

// Quiz
$verticalSpacing: 32px;
$narrowVerticalSpacing: 16px;
$horizontalSpacing: 16px;
$containerWidth: 726px;
$containerSmallWidth: 538px;
$containerNarrowWidth: 348px;

// General
$navbarHeight: 60px;
